import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import Input from "anchor-ui/input";
import { SetStateAction, useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";
import List from "anchor-ui/list";
import ListItem from "anchor-ui/list-item";
import Avatar from "anchor-ui/avatar";
import defaultAvatar from "../../assets/default_avatar.jpg"; // Assuming you have this asset
import { CometChat } from "@cometchat/chat-sdk-javascript"; // Import CometChat

interface FirebaseUser {
  uid: string;
  username?: string;
  email?: string;
  avatar?: string | null;
  [key: string]: any; // Allow other properties
}

const SearchUserByIpModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
  setCurrentChat,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  setCurrentChat: (chat: any) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [searchResults, setSearchResults] = useState<CometChat.User[]>([]);

  // Firebase callable function
  const searchUserOnIpCallable = httpsCallable<
    { ip: string },
    FirebaseUser[] // Expect FirebaseUser array from the function
  >(functions, "searchUserOnIp");

  const handleSearch = async () => {
    if (!ipAddress) {
      setAlert({ message: "Vul een IP-adres in", type: "warning" });
      return;
    }

    setIsLoading(true);
    setSearchResults([]); // Clear previous results

    try {
      const result = await searchUserOnIpCallable({ ip: ipAddress });
      const users = result.data || [];

      const cometChatUsers: CometChat.User[] = users.map((user) => {
        let userObj = new CometChat.User(user.uid);
        userObj.setName(user.name || "Onbekende Gebruiker");
        userObj.setAvatar(user.avatar || defaultAvatar);
        userObj.setRole(user.role || "user");
        userObj.setMetadata(user.metadata || {});
        return userObj;
      });

      setSearchResults(cometChatUsers);
      if (cometChatUsers.length === 0) {
        setAlert({ message: `Geen gebruikers gevonden met IP-adres: ${ipAddress}`, type: "info" });
      }
    } catch (error: any) {
      console.error("Error searching user by IP:", error);
      setAlert({
        message: `Er is een fout opgetreden bij het zoeken naar gebruikers met IP-adres: ${ipAddress}`,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIpAddress("");
    setSearchResults([]);
  };

  const handleOpenProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose} disabled={isLoading}>
            SLUITEN
          </Button>
          <Button flatButton onClick={handleSearch} disabled={isLoading || !ipAddress}>
            ZOEKEN
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <h2>Zoek Gebruiker op IP-adres</h2>
      <p>Voer een IP-adres in om te zoeken naar bijbehorende gebruikers.</p>
      <br /><br />

      <div className="search-container">
        <Input
          label="IP-adres"
          type="text"
          value={ipAddress}
          onChange={(e: { target: { value: SetStateAction<string> } }) => setIpAddress(e.target.value)}
          maxLength={50}
          disabled={isLoading}
        />
      </div>
      <br />

      {isLoading && <p>Bezig met zoeken...</p>}

      {searchResults.length > 0 && (
        <div>
          <h3>Zoekresultaten:</h3>
          <List header={`Gebruikers gevonden (${searchResults.length})`}>
            {searchResults.map((user) => (
              <ListItem
                key={user.getUid()}
                avatar={
                  <Avatar
                    image={user.getAvatar() || defaultAvatar}
                    alt={user.getName() || "Gebruiker"}
                  />
                }
                primaryText={user.getName() || "Onbekende Gebruiker"}
                onClick={() => handleOpenProfile(user)}
              />
            ))}
          </List>
        </div>
      )}
    </Modal>
  );
};

export default SearchUserByIpModal;